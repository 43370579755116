import React, { useState } from 'react'

export const usePopoverState = (userAnchorEl?: React.RefObject<Element>) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const open = Boolean(anchorEl)

  const onClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (userAnchorEl?.current) {
      setAnchorEl(userAnchorEl.current)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  return { open, onClose, handleClick, anchorEl }
}
