import { MenuItem, MenuList } from '@mui/material'
import React from 'react'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'

export interface IMenuClasses {
  menuItem?: string
  menuList?: string
  scrollbar?: string
}

export interface IDropdownMenuProps<Option extends { id: string | number }> {
  topAdornment?: React.ReactChild
  bottomAdornment?: React.ReactChild
  buttonId?: string
  formatOption: (opt: Option, idx: number) => React.ReactChild
  options: Option[]
  menuClasses?: IMenuClasses
  onOptionSelected: (opt: Option) => void
  ScrollbarComponent?: React.FC<{ className?: string }>
}

export const DropdownMenu = <Option extends { id: string | number }>(
  props: IDropdownMenuProps<Option>
) => {
  const {
    topAdornment,
    bottomAdornment,
    buttonId,
    formatOption,
    menuClasses,
    options,
    onOptionSelected,
    ScrollbarComponent,
  } = props

  const Scrollbar = ScrollbarComponent || 'div'

  return (
    <>
      {topAdornment}
      <MenuList className={menuClasses?.menuList} aria-labelledby={buttonId}>
        <Scrollbar className={menuClasses?.scrollbar}>
          {options.map((opt, idx) => {
            return (
              <MenuItem
                key={opt.id}
                onClick={() => onOptionSelected(opt)}
                className={menuClasses?.menuItem}
              >
                {formatOption(opt, idx)}
              </MenuItem>
            )
          })}
        </Scrollbar>
      </MenuList>
      {bottomAdornment && <LightGrayDivider />}
      {bottomAdornment}
    </>
  )
}
