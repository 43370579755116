import React, { MouseEventHandler } from 'react'
import styles from './Backdrop.module.scss'

type BackdropProps = {
  onClick: MouseEventHandler<HTMLDivElement>
}

export const Backdrop: React.FC<BackdropProps> = (props) => {
  return <div className={styles.backdrop} onClick={props.onClick} />
}
