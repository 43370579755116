import { Button, ButtonClasses, SvgIcon } from '@mui/material'
import { ReactComponent as ArrowDownIcon } from 'assets/icon/designsystem/keyboard_arrow_down.svg'
import clsx from 'clsx'
import styles from './DropdownButton.module.scss'

export interface IDropdownButtonProps extends React.ComponentProps<typeof Button> {
  title: string
  buttonId: string
  dropdownId: string
  className?: string
  classes?: Partial<ButtonClasses>
  justifyContent?: 'spaceBetween'
  borderColor?: 'darkGray' | 'lightGray'
  expanded: boolean
  withEndIcon?: boolean
}

export const DropdownButton: React.FC<IDropdownButtonProps> = (props) => {
  const {
    buttonId,
    dropdownId,
    title,
    className,
    classes,
    justifyContent,
    borderColor = 'darkGray',
    children,
    expanded,
    withEndIcon = true,
    ...rest
  } = props

  const withTitle = Boolean(title)

  return (
    <>
      <Button
        id={buttonId}
        variant={'outlined'}
        aria-controls={dropdownId}
        aria-haspopup="true"
        aria-expanded={expanded ? 'true' : undefined}
        endIcon={withEndIcon && <SvgIcon component={ArrowDownIcon} />}
        className={clsx(
          styles.button,
          styles[`${borderColor}Border`],
          className,
          props.disabled && styles.disabled
        )}
        classes={{
          ...classes,
          root: clsx({ [styles.onlyIcon]: !withTitle }, classes?.root),
          startIcon: clsx({ [styles.noMargin]: !withTitle }, classes?.startIcon),
          endIcon: clsx(
            { [styles.nailToEdgeEndIcon]: justifyContent === 'spaceBetween' },
            classes?.endIcon
          ),
        }}
        {...rest}
      >
        {withTitle && <span className={styles.title}>{title}</span>}
      </Button>
      {children}
    </>
  )
}
